import { composeValidators, maxLength, minLength, regex } from 'ra-core';

export const validatePassword = composeValidators([
    minLength(14),
    maxLength(60),
    regex(
        /^(?=.*[a-z].*[a-z]).*$/,
        'apihub.validation.password.at_least_two_lowercase_character'
    ),
    regex(
        /^(?=.*[A-Z].*[A-Z]).*$/,
        'apihub.validation.password.at_least_two_uppercase_character'
    ),
    regex(/^(?=.*[0-9]).*$/, 'apihub.validation.password.at_least_one_number'),
    regex(
        /^(?=.*[!@#$%^&*-]).*$/,
        'apihub.validation.password.at_least_one_special_character'
    ),
]);
