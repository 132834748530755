import { makeStyles, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import { useAuthState } from 'ra-core';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import shipmentStatusIcon from '../../ui/images/icon_Shipment Status.svg';
import gateReceiptsIcon from '../../ui/images/icon_Gate Receipt.svg';
import tripPlanIcon from '../../ui/images/icon_Trip Plan.svg';
import { UnAuthenticatedLayoutWithTheme } from './UnAuthenticatedLayout';
import { Routes } from '../../ui/constants';
import LandingPagePrimaryImage from '../../ui/images/LandingPagePrimary.png';

export const LandingPage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { loading, authenticated } = useAuthState();
    let contactUsRef = React.useRef();
    const location = useLocation();

    useEffect(() => {
        if (
            !contactUsRef.current ||
            (contactUsRef.current &&
                location &&
                location.pathname &&
                location.pathname.indexOf('contact-us') === -1)
        )
            return;

        document.getElementById('contact-us').scrollIntoView({
            behavior: 'smooth',
        });
    });

    if (loading) {
        return null;
    }

    if (authenticated) {
        return <Redirect to={Routes.default} />;
    }

    return (
        <UnAuthenticatedLayoutWithTheme showActions>
            <div className={classes.background}>
                <Grid className={classes.heroContainer} container>
                    <Grid item xs={12} md={12} lg={7}>
                        <div className={classes.features}>
                            <Typography variant="h2" color="textSecondary">
                                NS API Resource Platform
                            </Typography>
                            <Typography
                                className={classes.heroDescription}
                                color="textSecondary"
                            >
                                <ion-icon name="bulb-outline"></ion-icon>
                                Power your applications with real-time
                                information
                            </Typography>
                            <Typography
                                className={classes.heroDescription}
                                color="textSecondary"
                            >
                                <ion-icon name="bulb-outline"></ion-icon>
                                Integrate directly with Norfolk Southern data
                            </Typography>
                            <Typography
                                className={classes.heroDescription}
                                color="textSecondary"
                            >
                                <ion-icon name="bulb-outline"></ion-icon>
                                Increase shipment visibility
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={5}>
                        <div className={classes.heroButtonsContainer}>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to="/signup"
                                color="primary"
                            >
                                Register
                            </Button>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to="/api-faqs"
                                color="primary"
                            >
                                View FAQs
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                href="#contact-us"
                                ref={contactUsRef}
                            >
                                Contact us
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <GridList
                    cellHeight="auto"
                    cols={3}
                    rows={1}
                    spacing={theme.spacing(4)}
                    className={classes.cards}
                >
                    <GridListTile>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardIcon}>
                                <img src={tripPlanIcon} alt="Trip Plan" />
                            </CardContent>
                            <CardActions className={classes.actionContainer}>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    className={classes.actionItems}
                                >
                                    Trip Plan API
                                </Typography>
                            </CardActions>
                            <CardContent>
                                <Typography
                                    className={classes.actionDescription}
                                >
                                    This API provides the current ETA, planned
                                    future movement events, and completed
                                    movement events for each shipment.
                                </Typography>
                            </CardContent>
                        </Card>
                    </GridListTile>
                    <GridListTile>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardIcon}>
                                <img
                                    src={shipmentStatusIcon}
                                    alt="Shipment Status"
                                />
                            </CardContent>
                            <CardActions className={classes.actionContainer}>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    className={classes.actionItems}
                                >
                                    Shipment Status API
                                </Typography>
                            </CardActions>
                            <CardContent>
                                <Typography
                                    className={classes.actionDescription}
                                >
                                    This API provides current location, ETA, and
                                    basic waybill data for shipments.
                                </Typography>
                            </CardContent>
                        </Card>
                    </GridListTile>
                    <GridListTile>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardIcon}>
                                <img
                                    src={gateReceiptsIcon}
                                    alt="Gate Receipts"
                                />
                            </CardContent>
                            <CardActions className={classes.actionContainer}>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    className={classes.actionItems}
                                >
                                    Gate Receipts API
                                </Typography>
                            </CardActions>
                            <CardContent>
                                <Typography
                                    className={classes.actionDescription}
                                >
                                    Provides Gate Receipt data, Terminal
                                    Information, Driver Information and the
                                    pickup number for each Equipment ID.
                                </Typography>
                            </CardContent>
                        </Card>
                    </GridListTile>
                </GridList>
                <div className={classes.introductionContainer}>
                    <Typography
                        variant="h3"
                        align="center"
                        className={classes.sectionTitleLight}
                    >
                        API Introduction
                    </Typography>
                    <Typography className={classes.sectionText}>
                        An API (Application Programming Interface) is a set of
                        functions that allows applications to access data and
                        interact with external software components, operating
                        systems, or microservices. To simplify, an API delivers
                        a request to a system and then in return, a response is
                        sent back to a user. In technical terms , it is a
                        webservice call to NS with a security key and input
                        parameters, and the response is the requested data in a
                        structured format.
                    </Typography>
                    <Typography className={classes.sectionText}>
                        APIs are not event driven such as train passing a
                        station and triggering data to be sent. Rather, they are
                        pull requests periodic requests for the specific API are
                        made with the expectation that the corresponding data
                        will be returned.
                    </Typography>
                </div>
            </div>
            <div className={classes.apisContainer}>
                <Typography
                    variant="h3"
                    align="left"
                    className={classes.sectionTitle}
                >
                    Why APIs?
                </Typography>
                <Typography variant="h5" className={classes.subtitle}>
                    Real time Access
                </Typography>
                <Typography className={classes.sectionTextDark}>
                    Direct access into Norfolk Southern’s data, providing you
                    with the most current, real-time information available.
                </Typography>
                <Typography variant="h5" className={classes.subtitle}>
                    Increased Automation
                </Typography>
                <Typography className={classes.sectionTextDark}>
                    Eliminate the need to manual search for data by leveraging
                    APIs.
                </Typography>
                <Typography variant="h5" className={classes.subtitle}>
                    Integration of Supply Chain Data
                </Typography>
                <Typography className={classes.sectionTextDark}>
                    Connect Norfolk Southern’s data directly with your broader
                    supply chain information.
                </Typography>
            </div>
        </UnAuthenticatedLayoutWithTheme>
    );
};

const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url('${LandingPagePrimaryImage}')`,
        backgroundOrigin: 'padding-box',
        backgroundClip: 'padding-box',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        margin: '0 -176px',
        paddingLeft: '176px',
        paddingRight: '176px',
        [theme.breakpoints.down('md')]: {
            margin: '0px -62px',
            paddingLeft: '24px',
            paddingRight: '24px',
        },
    },
    heroContainer: {
        marginTop: '70px',
        color: theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
            marginTop: '40px',
        },
    },
    features: {
        width: '80%',
        margin: '0 auto',
        '& h2': {
            width: '80%',
            margin: '0 auto 24px auto',
            [theme.breakpoints.down('md')]: {
                width: 'initial',
                margin: '0 auto',
            },
        },
        [theme.breakpoints.down('md')]: {
            width: 'initial',
        },
    },
    heroDescription: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        margin: '24px auto',
        width: '80%',
        '& ion-icon': {
            fontSize: '20px',
            margin: '0 20px 0 5px',
            color: '#22bcb5',
        },
        [theme.breakpoints.down('md')]: {
            width: 'initial',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'initial',
            fontSize: '14px',
            lineHeight: '17px',
            letterSpacing: '0.07px',
        },
    },
    heroImage: {
        width: 'inherit',
        maxWidth: '365px',
    },
    subtitle: {
        color: '#034764',
        margin: '24px 0',
        [theme.breakpoints.down('md')]: {
            marginBottom: '10px',
            fontSize: '18px',
            lineHeight: '23px',
            letterSpacing: '0.18px',
        },
    },
    heroButtonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& button': {
            marginRight: '12px',
            marginBottom: theme.spacing(2),
            '&:last': {
                marginBottom: '6px',
            },
        },
        '& button:last': {
            marginRight: '12px',
            marginBottom: '6px',
        },
        '& a': {
            marginRight: '12px',
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('md')]: {
            display: 'grid',
        },
    },
    secondaryButton: {
        marginLeft: theme.spacing(2),
    },
    sectionTitle: {
        color: '#027B75',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            marginBottom: '16px',
        },
    },
    sectionTitleLight: {
        color: '#22bcb5',
        marginTop: '74px',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            marginTop: '16px',
        },
    },
    sectionText: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        color: '#E7E7E2',
        '& ion-icon': {
            fontSize: '18px',
            margin: '0 20px 0 5px',
            color: '#22bcb5',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '17px',
            letterSpacing: '0.07px',
        },
    },
    sectionTextDark: {
        color: '#292625',
        [theme.breakpoints.down('md')]: {
            marginBottom: '25px',
            fontSize: '14px',
            lineHeight: '17px',
            letterSpacing: '0.07px',
        },
    },
    introductionContainer: {
        paddingBottom: '126px',
        [theme.breakpoints.down('md')]: {
            paddingBottom: '72px',
        },
    },
    apisContainer: {
        background: '#E7E7E2',
        margin: '0px -176px',
        padding: '0 176px 26px 176px',
        width: 'inherit',
        [theme.breakpoints.down('md')]: {
            margin: '0px -62px',
            paddingTop: '18px',
            paddingLeft: '24px',
            paddingRight: '24px',
        },
    },
    cards: {
        justifyContent: 'center',
        margin: '0 !important',
        '& > li': {
            width: 'inherit !important',
            padding: '0 !important',
            margin: '24px',
        },
    },
    card: {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        color: theme.palette.common.black,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '278px',
        '& > :first-child': {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            background: '#f6f6f4',
        },
    },
    cardIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    actionContainer: {
        height: '54px',
        padding: '24px 16px 8px 16px',
        justifyContent: 'center',
    },
    actionDescription: {
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '17px',
            letterSpacing: '0.07px',
        },
    },
    actionItems: {
        color: '#027B75',
    },
}));
