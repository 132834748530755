import { useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const FeedbackButton = props => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.div}>
            <Button
                classes={{ label: classes.label }}
                className={classes.button}
                onClick={() =>
                    window.open(props.to, '_blank', 'noopener,noreferrer')
                }
                variant="contained"
                color="primary"
            >
                {translate('common.labels.feedback')}
            </Button>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    div: {
        position: 'fixed',
        zIndex: '99999',
    },
    button: {
        position: 'fixed',
        border: '1px solid #157B75',
        top: '50vh',
        right: '0px',
        writingMode: 'vertical-rl',
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        fontSize: '18px',
        lineHeight: '20px',
        letterSpacing: '0.18px',
        height: '113px',
        minWidth: '38px',
        width: '38px',
    },
    label: {
        transform: 'rotate(180deg)',
        '-moz-transform': 'rotate(180deg)',
    },
}));
