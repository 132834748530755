import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
    Notification,
    setSidebarVisibility,
    ComponentPropType,
} from 'react-admin';
import { createTheme } from '@material-ui/core';
import { nscorpTheme } from './theme';
import { ApiHubAppBar } from './ApiHubAppBar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        position: 'relative',
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
    },
    contentWithSidebar: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        padding: '30px',
        marginTop: '8em',
    },
}));

const MarketplaceLayout = ({ children, dashboard, logout, title }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    useEffect(() => {
        dispatch(setSidebarVisibility(true));
    }, [setSidebarVisibility]);

    return (
        <ThemeProvider theme={createTheme(nscorpTheme)}>
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <ApiHubAppBar title={title} open={open} logout={logout} />
                    <main className={classes.contentWithSidebar}>
                        <div className={classes.content}>{children}</div>
                    </main>
                    <Notification />
                </div>
            </div>
        </ThemeProvider>
    );
};

MarketplaceLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    logout: ComponentPropType,
    title: PropTypes.string.isRequired,
};

export default MarketplaceLayout;
