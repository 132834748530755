import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Docs1 from 'app/src/ui/images/doc_applications1.png';
import Docs2 from 'app/src/ui/images/doc_applications2.png';
import Docs3 from 'app/src/ui/images/doc_applications3.png';
import Docs4 from 'app/src/ui/images/doc_applications4.png';
import Docs5 from 'app/src/ui/images/doc_applications5.png';
import Docs6 from 'app/src/ui/images/doc_applications6.png';

export const ApiDocs = () => {
    const classes = useStyles();

    return (
        <>
            <Grid className={classes.root} container spacing={6}>
                <Grid item xs={6}>
                    <div className={classes.section}>
                        <div className={classes.section}>
                            <Typography gutterBottom>
                                In order to hit the APIs, please follow the
                                steps mentioned below:
                            </Typography>
                            <br></br>
                            <Typography gutterBottom>
                                1. Find your client id and scope from:
                            </Typography>
                            <ol className={classes.ol}>
                                <li>
                                    Click on Applications
                                    <div className={classes.docs1} />
                                </li>
                                <br></br>
                                <li>
                                    Click on your company name
                                    <div className={classes.docs2} />
                                </li>
                                <br></br>
                                <li>
                                    Click on api key 1 to view your scope and
                                    client_id
                                    <div className={classes.docs3} />
                                </li>
                            </ol>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.section}>
                        <br></br>
                        <br></br>
                        <Typography gutterBottom>
                            2. Find your Client Secret in the email that was
                            sent to you upon registration:
                        </Typography>
                        <div className={classes.docs4} />
                        <div className={classes.docs5} />
                        <br></br>
                        <Typography gutterBottom>
                            3. Generate bearer token using your client_id,
                            client_secret and scope.
                        </Typography>
                        <ol className={classes.ol}>
                            <li>
                                cURL Request:
                                <br />
                                curl --location --request POST
                                'https://api.nscorp.com/external/oauth/v2/token'
                                \
                                <br />
                                --header 'Content-Type:
                                application/x-www-form-urlencoded' \<br />
                                --data-urlencode 'grant_type=
                                <span className={classes.alert}>
                                    client_credentials
                                </span>
                                ' \<br />
                                --data-urlencode 'scope=
                                <span className={classes.alert}>
                                    YOUR SCOPE
                                </span>
                                ' \
                                <br />
                                --data-urlencode 'client_id=
                                <span className={classes.alert}>
                                    YOUR CLIENT ID
                                </span>
                                ' \<br />
                                --data-urlencode 'client_secret=
                                <span className={classes.alert}>
                                    YOUR CLIENT SECRET
                                </span>
                                '
                            </li>
                            <br></br>
                            <li>
                                Response:
                                <br />
                                {'{'}
                                <br />
                                &emsp;"access_token":{' '}
                                <span className={classes.alert}>
                                    "YOUR TOKEN"
                                </span>
                                ,
                                <br />
                                &emsp;"token_type": "Bearer",
                                <br />
                                &emsp;"expires_in": 3600,
                                <br />
                                &emsp;"scope":{' '}
                                <span className={classes.alert}>
                                    "YOUR SCOPE"
                                </span>
                                <br />
                                {'}'}
                            </li>
                        </ol>
                        <br />
                        <Typography gutterBottom>
                            4. Hit APIs of your choice by using the bearer
                            token:
                        </Typography>
                        <div className={classes.docs6} />
                        <br></br>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = makeStyles(
    theme => ({
        root: {
            wordBreak: 'break-all',
        },
        code: {
            fontSize: theme.typography.fontSize,
        },
        section: {
            margin: '16px 4px',
        },
        alert: {
            color: '#ff0000',
        },
        ol: {
            listStyleType: 'lower-alpha',
            fontFamily: 'adelle-sans-regular, sans-serif',
            '& li': {
                margin: '0 0 20px 0',
            },
            '& div': {
                marginTop: '12px',
            },
        },
        docs1: {
            backgroundImage: `url('${Docs1}')`,
            height: '200px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        docs2: {
            backgroundImage: `url('${Docs2}')`,
            height: '298px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        docs3: {
            backgroundImage: `url('${Docs3}')`,
            height: '434px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        docs4: {
            backgroundImage: `url('${Docs4}')`,
            height: '170px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        docs5: {
            backgroundImage: `url('${Docs5}')`,
            height: '250px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        docs6: {
            backgroundImage: `url('${Docs6}')`,
            height: '250px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
    }),
    {
        name: 'NSARP_Docs',
    }
);
