import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import {
    Filter,
    LoadingIndicator,
    maxLength,
    minLength,
    sanitizeListRestProps,
    SearchInput,
    TextField,
    TopToolbar,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Inbox from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';

import { readApiHubPreference } from '../preferences';
import {
    Datagrid,
    LIST_DISPLAY_CARDS,
    ListDisplayProvider,
    NoPaginationList,
} from '../ui';

const listDisplayPreferenceName = 'listDisplay/companies';

const CompanyListComponent = props => <div {...props} />;

const defaultSort = { field: 'name', order: 'DESC' };

const CompanyListActions = ({
    className,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    permanentFilter,
    resource,
    showFilter,
    ...props
}) => {
    const classes = useApiListActionsStyles();

    return (
        <TopToolbar
            className={classnames(classes.root, className)}
            {...sanitizeListRestProps(props)}
        ></TopToolbar>
    );
};

const CompanyFilters = props => {
    const translate = useTranslate();
    const classes = useApiFilterStyles();

    useEffect(() => {
        console.log('Loading : ', props.loading);
    }, [props.loading]);
    return (
        <>
            <Filter {...props}>
                <SearchInput
                    source="q"
                    className={classes.searchInput}
                    alwaysOn
                    validate={[minLength(2), maxLength(300)]}
                    placeholder={translate(
                        'resources.registrations.filters.search'
                    )}
                />
            </Filter>
            <div
                className="col-12"
                style={{ top: '120px', position: 'relative', left: '-14%' }}
            >
                {props.loading ? <LoadingIndicator /> : ''}
            </div>
        </>
    );
};

export const CompanyList = props => {
    const initialListDisplay = readApiHubPreference(
        listDisplayPreferenceName,
        LIST_DISPLAY_CARDS
    );

    const handleClickClose = data => {
        props.handleClose(data);
    };

    return (
        <ListDisplayProvider
            initialListDisplay={initialListDisplay}
            preferenceName={listDisplayPreferenceName}
        >
            <NoPaginationList
                emptyWhileLoading={<Empty />}
                actions={<CompanyListActions />}
                filters={<CompanyFilters />}
                sort={defaultSort}
                bulkActionButtons={false}
                component={CompanyListComponent}
                empty={false}
                pagination={<div />}
                {...props}
            >
                <CompanyListDisplay handleClose={handleClickClose} />
            </NoPaginationList>
        </ListDisplayProvider>
    );
};

const Empty = ({ basePath, ...props }) => {
    const classes = useEmptyStyles(props);
    const translate = useTranslate();
    return (
        <>
            <div className={classes.message}>
                <Inbox className={classes.icon} />
                <Typography variant="h6" paragraph>
                    {translate('resources.applications.fields.noApplications')}
                </Typography>
            </div>
        </>
    );
};

const useEmptyStyles = makeStyles(
    theme => ({
        message: {
            textAlign: 'center',
            opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
            margin: '0 1em',
            color:
                theme.palette.type === 'light'
                    ? 'inherit'
                    : theme.palette.text.primary,
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
    }),
    { name: 'Empty' }
);

const CompanyListDisplay = props => {
    const classes = useCompanyListStyles(props);
    const translate = useTranslate();

    const handleSelection = (id, resource, record) => {
        if (record && record.name) {
            props.handleClose({
                name: record.name,
                lineBusiness: record.lineOfBusiness,
                scacs: record.scacs,
                classCode: record.classCode,
                primarySix: record.primarySix,
                railCustomerNumber: record.railCustomerNumber,
            });
        }
    };

    return (
        <>
            <div className={classes.companyList}>
                {translate('resources.registrations.fields.search_company')}
            </div>
            <Card>
                <Datagrid
                    type="signup"
                    rowClick={handleSelection}
                    {...props}
                    optimized
                >
                    <TextField source="name" sortable={false} />
                    <TextField source="primarySix" sortable={false} />
                    <TextField source="lineOfBusiness" sortable={false} />
                </Datagrid>
            </Card>
        </>
    );
};

const useCompanyListStyles = makeStyles({
    companyList: {
        marginBottom: '14px',
        marginTop: '8px',
    },
});

const useApiFilterStyles = makeStyles({
    searchInput: {
        minWidth: '300px',
    },
});

const useApiListActionsStyles = makeStyles(
    theme => ({
        root: {
            alignItems: 'center',
        },
        button: {
            marginLeft: theme.spacing(),
        },
    }),
    { name: 'CompanyList' }
);
