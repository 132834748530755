import { useEffect, useState } from 'react';

import { useApiHub } from '../ApiHubContext';
//import { getFetchJson } from '../fetchUtils';

export const fetchResetPassword = async (
    urlWithTenant,
    originHubName,
    username
) => {

    /***** Old implementation which is is not used as per the fix done  in ANS-13199 ****
     /* const fetchJson = getFetchJson(originHubName);

     const { json } = await fetchJson(
         `${urlWithTenant}/ResetMyPassword()?Username="${username}"`
     );

     return await json; */

    let response = await fetch(
        `${urlWithTenant}/reset-my-password`, {
        "method": "PUT",
        'body': JSON.stringify({ "userName": username }),
        "headers": {
            'Origin-Hub-Name': originHubName,
            'Accept-Language': 'en',
            'content-type': 'application/json; charset=UTF-8'
        }
    });

    return response;
};

export const useResetPassword = () => {
    const { urlWithTenant, originHubName } = useApiHub();
    const [username, setUsername] = useState('');
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        if (!fetched && username !== '') {
            fetchResetPassword(urlWithTenant, originHubName, username).then(
                () => {
                    setFetched(true);
                }
            );
        }
    }, [urlWithTenant, fetched, username, originHubName]);

    return [username, setUsername];
};
