import React from 'react';
import ReactDOM from 'react-dom';
import { startApiHubMockedServer } from 'layer7-apihub-mock';

import './index.css';
import App from './App';

const { ENABLE_MOCK, MOCK_SERVER_INDICATOR_LINK } = global.APIHUB_CONFIG;

export const shouldEnableMock = (enableMock = ENABLE_MOCK) =>
    enableMock === 'true' || enableMock === true;

if (!shouldEnableMock(ENABLE_MOCK)) {
    ReactDOM.render(<App />, document.getElementById('root'));
} else {
    console.log('Starting the mocked server');
    startApiHubMockedServer({
        runningIndicatorLink: MOCK_SERVER_INDICATOR_LINK,
    }).then(() => ReactDOM.render(<App />, document.getElementById('root')));
}
