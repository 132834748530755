import { required, TextInput, useTranslate } from 'react-admin';
import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import Modal from '@material-ui/core/Modal';
import { useForm } from 'react-final-form';
import Button from '@material-ui/core/Button';

import { CompanyList } from './CompanyList';

export const CompanyLookupInput = props => {
    let tempProps = { ...props };

    const classes = useStyles();
    const translate = useTranslate();
    const form = useForm();
    const [isOpen, setIsOpen] = useState();

    const handleCompanySearch = () => {
        setIsOpen(true);
    };

    const handleCloseCompanies = data => {
        if (data && data.name !== undefined) {
            form.change('Company', data.name);
            props.scacs(data.scacs);
            props.customerType(
                filterCustomerType(data.classCode, data.lineBusiness)
            );
            props.primarySix(data.primarySix);
            props.railCustomerNumber(data.railCustomerNumber);
            props.classCode(
                data.classCode.map(el => {
                    return {
                        classCode: el.classCode,
                        stationCode: el.stationCode,
                    };
                })
            );
        }

        setIsOpen(false);
    };

    const ValidateCompany = [required()];

    const filterCustomerType = (classCode, lineOfBusniess) => {
        switch (lineOfBusniess) {
            case 'SINGLE CAR OR UNIT TRAIN':
                if (classCode && classCode.length > 0) return 'IP';
                return 'FF';
            case 'SHORT LINE':
                return 'SL';
            case 'DRAY':
                return 'DRAY';
            case 'INTERMODAL':
            case 'IM':
                return 'IM';
            case 'IP':
                return 'IP';
            case 'THIRD PARTY PROVIDER':
            case 'TPL':
                return 'TPL';
            default:
                return 'FF';
        }
    };

    tempProps = {
        source: 'Company',
        type: 'text',
        className: '',
        id: 'Company',
        hasList: true,
        hasEdit: false,
        hasShow: false,
        hasCreate: false,
        resource: 'companies',
        basePath: '/signup',
    };

    return (
        <Grid container spacing={0}>
            <Grid item xs={4} className={classes.buttonContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleCompanySearch}
                >
                    {translate('resources.registrations.fields.company_search')}
                </Button>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={7}>
                <TextInput
                    source="Company"
                    inputProps={{ title: form.getState().values['Company'] }}
                    type="text"
                    label="resources.registrations.fields.company"
                    variant="outlined"
                    fullWidth
                    resettable
                    parse={v => {}}
                    validate={ValidateCompany}
                    disabled={true}
                ></TextInput>
            </Grid>
            <div>
                <Modal
                    open={isOpen}
                    onClose={handleCloseCompanies}
                    aria-labelledby="company-search-modal"
                >
                    <div className={classnames(classes.modal, classes.paper)}>
                        <CompanyList
                            handleClose={handleCloseCompanies}
                            {...tempProps}
                        />
                        <div className={classes.close}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                {translate('common.labels.close')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Grid>
    );
};

const useStyles = makeStyles(
    theme => ({
        paper: {
            position: 'absolute',
            width: 800,
            height: 700,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        lookup: {
            marginBottom: '14px',
        },
        modal: {
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%)`,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            flexBasis: '100%',
            backgroundColor: 'transparent',
            padding: 0,
            marginTop: '8px',
        },
        close: {
            position: 'absolute',
            bottom: '0',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px 0',
        },
    }),
    {
        name: 'CompanyLookupInput',
    }
);
