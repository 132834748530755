import React from 'react';
import { Link } from 'react-router-dom';

export const BrandLogo = props => {
    let path = props.img || 'Horizontal App Logo_Black.png';

    return (
        <Link to="/">
            <img className={props.className} src={path} alt={'Logo'} />
        </Link>
    );
};
