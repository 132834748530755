import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CRUD_CREATE, useCreate, useTranslate } from 'ra-core';

import { SignUpForm } from './SignUpForm';
import { AuthenticationLayout } from '../AuthenticationLayout';
import { useLayer7Notify } from '../../useLayer7Notify';
import { getIsConfirmedAccount, setIsConfirmedAccount } from '../Confirmation';

export const SignUp = props => {
    const translate = useTranslate();
    const classes = useStyles(props);
    const notify = useLayer7Notify();
    const isConfirmedAccount = getIsConfirmedAccount() || false;

    useEffect(() => {
        setTimeout(() => {
            if (isConfirmedAccount) {
                notify(
                    'resources.registrations.notifications.temporary_email_confirmation_success',
                    'info',
                    {},
                    false,
                    8000
                );
                setIsConfirmedAccount(false)
            }
        }, 2000);
    }, [isConfirmedAccount]);

    return (
        <>
            <Typography
                variant='h4'
                color='textPrimary'
                className={classes.title}
            >
                {translate('resources.registrations.title')}
            </Typography>
            <SignUpForm {...props} />
        </>
    );
};

/**
 * The page displaying the reset password form
 *
 * @param {*} Header A React Component used as the page header
 * @param {*} Content A React Component used to display some content next to the reset password form
 * @param {*} Footer A React Component used as the page footer
 *
 * @example <caption>Simple usage</caption>
 * <ResetPasswordPage />
 *
 * const MyApp = props => <Admin resetPasswordPage={MyResetPasswordPage} {...props} />
 *
 * @example <caption>With customized parts</caption>
 * const Header = () => <header><h1>My company</h1></header>
 * const Footer = () => <footer>Copyright © 2020 My Company. All Rights Reserved</footer>
 * const Content = () => <section><p>Welcome to My Product.</p></section>
 *
 * const MyResetPasswordPage = props => (
 *     <ResetPasswordPage
 *         Header={CustomHeader}
 *         Content={CustomContent}
 *         Footer={CustomFooter}
 *         {...props}
 *     />
 * );
 *
 * const MyApp = props => <Admin resetPasswordPage={MyResetPasswordPage} {...props} />
 */
export const SignUpPage = props => (
    <AuthenticationLayout {...props}>
        <SignUp />
    </AuthenticationLayout>
);

export const useSignup = () => {
    const [create, state] = useCreate('registrations');

    const signup = (data, { onSuccess, onFailure }) => {
        create(
            {
                payload: {
                    firstName: data.FirstName,
                    lastName: data.LastName,
                    email: data.Email,
                    eula: data.Terms,
                    companyName: data.Company,
                    scac: data.Scac,
                    customerType: data.CustomerType,
                    eid: data.Eid,
                    customerNumber: data.CustomerNumber,
                    customerLocation: data.CustomerLocation
                },
            },
            {
                action: CRUD_CREATE,
                onSuccess: () => {
                    onSuccess();
                },
                onFailure: (error) => {
                    onFailure(error);
                },
            }
        );
    };

    return [signup, state];
};

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(6),
    },
    errorIcon: {
        '& ion-icon': {
            color: '#FFCB00',
            height: '35px',
            width: '35px',
        },
    },
    confirmation: {
        marginTop: '15px',
    },
}));
