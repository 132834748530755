import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';

import { Create } from '../ui';
import { ApplicationNew } from './ApplicationNew';
import { useUserContext } from '../userContexts';

const useStyles = makeStyles(
    {
        root: {},
        card: {},
    },
    {
        name: 'Layer7ApplicationCreate',
    }
);

export const ApplicationCreate = props => {
    const { root: rootClassName, ...classes } = useStyles(props);
    const { permissions, id, ...rest } = props;
    const [userContext] = useUserContext();
    const [canCreateApp, setCanCreateApp] = React.useState(false);
    const translate = useTranslate();

    React.useEffect(() => {
        if (userContext && !userContext.userDetails.developer) {
            setCanCreateApp(true);
        }
    }, [userContext]);

    return (
        canCreateApp && (
            <Create
                className={rootClassName}
                classes={classes}
                title={translate('resources.applications.actions.addApplication')}
                {...rest}
            >
                <ApplicationNew userContext={userContext} />
            </Create>
        )
    );
};
