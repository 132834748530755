import React, { useMemo } from 'react';
import { Datagrid as RaDatagrid } from 'react-admin';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export const Datagrid = props => {
    const theme = useTheme();
    let classes = useStyles(props);

    // HACK: For some reason, the header cells loses their styles when dynamically
    // changing the theme. Passing a new key when changing the theme fixes that
    const key = useMemo(() => Math.random(), [theme]); // eslint-disable-line

    if (props && props.type && props.type === 'signup') {
        classes = useStylesSignup(props);
    }

    return <RaDatagrid key={key} classes={classes} {...props} />;
};

const computeDatagridPadding = (theme, props) =>
    props && props.size === 'small'
        ? `${theme.spacing(0.5)}px ${theme.spacing(3)}px`
        : `${theme.spacing(1)}px ${theme.spacing(3)}px`;

const useStyles = makeStyles(
    theme => ({
        headerCell: {
            backgroundColor: theme.palette.action.selected,
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'uppercase',
        },
    }),
    {
        name: 'Layer7Datagrid',
    }
);

const useStylesSignup = makeStyles(
    theme => ({
        headerCell: {
            backgroundColor: theme.palette.action.selected,
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'uppercase',
            '&:first-child': {
                width: '385px',
            },
            '&:nth-child(2)': {
                width: '146px',
            },
            '&:nth-child(3)': {
                width: '201px',
            },
        },
        headerRow: {
            display: 'block',
        },
        tbody: {
            display: 'block',
            maxHeight: '300px',
            overflowY: 'scroll',
        },
        rowCell: {
            padding: props => computeDatagridPadding(theme, props),
            '&:first-child': {
                width: '385px',
            },
            '&:nth-child(2)': {
                width: '146px',
            },
            '&:nth-child(3)': {
                width: '184px',
            },
        },
    }),
    {
        name: 'Layer7Datagrid',
    }
);
