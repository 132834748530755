import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import {
    IconButton,
    Input,
    InputAdornment,
    OutlinedInput,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';

const useApiFilterStyles = makeStyles({
    searchInput: {
        minWidth: '80px',
        marginTop: '32px',
        marginRight: '14px',
        marginBottom: '4px',
        background: '#f2f2f2',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        '&:hover': {
            background: '#dedede',
        },
    },
});

export function ListArrayInputFilter(props) {
    const { filter, setFilter } = props;
    const translate = useTranslate();
    const classes = useApiFilterStyles();

    return (
        <>
            {/* <SearchInput
                source="q"
                onChange={event => setFilter(event?.target?.value || '')}
                placeholder={translate(
                    'resources.applications.actions.searchByApiTitle'
                )}
                
                className={classes.searchInput}
                alwaysOn
            /> */}

            <Input
                id="outlined-adornment"
                type={'text'}
                variant={'standard'}
                focused
                className={classes.searchInput}
                onChange={event => setFilter(event?.target?.value || '')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                        >
                            <Search />
                        </IconButton>
                    </InputAdornment>
                }
                placeholder={translate(
                    'resources.applications.actions.searchByApiTitle'
                )}
            />
        </>
    );
}
