import React from 'react';
import { SaveButton, Toolbar, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

/**
 * The Application Toolbar displaying the submit button and the possible errors of the account setup form
 *
 * @param {string} button.color The color of the submit button
 * @param {string} button.variant The variant of the submit button
 * @param {string} button.size The size of the submit button
 *
 */
export const ApplicationToolbar = props => {
    const classes = useStyles(props);

    const { button, buttonLabel } = props;
    const color = get(button, 'color', 'primary');
    const variant = get(button, 'variant', 'contained');
    const size = get(button, 'size', 'large');
    return (
        <>
            <Toolbar className={classes.toolbar} {...props}>
                <CancelButton />
                <SaveButton
                    className={classes.submit}
                    icon={<span />}
                    label={buttonLabel}
                    color={color}
                    variant={variant}
                    size={size}
                />
            </Toolbar>
        </>
    );
};

const CancelButton = ({ record }) => {
    const translate = useTranslate();

    return (
        <Button
            component={Link}
            to={record ? `/applications/${record.id}/show` : '/applications'}
            variant="outlined"
            color="secondary"
        >
            {translate('common.labels.cancel')}
        </Button>
    );
};

const useStyles = makeStyles(
    theme => ({
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(2),
            margin: '0 16px 16px 16px',
        },
        error: {
            marginTop: theme.spacing(2),
        },
        success: {
            color: theme.palette.success.main,
            marginTop: theme.spacing(2),
        },
        submit: {
            marginLeft: theme.spacing(2),
        },
    }),
    {
        name: 'Layer7ApplicationToolbar',
    }
);

const subscription = { error: true, touched: true, submitSucceeded: true };
