import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CRUD_UPDATE, useTranslate, useUpdate } from 'ra-core';
import { useLocation, useHistory } from 'react-router-dom';

import { SignUpForm } from '../SignUp';
import { AuthenticationLayout } from '../AuthenticationLayout';
import { useLayer7Notify } from '../../useLayer7Notify';

const ACC_CONFIRMED = '@layer7/authentication/accConfirmed';

const getConfirmationId = (location) => {
    const CONFIRMATION_MATCH = /(?<=confirmation\/)[^\s]*/;
    const match = location.pathname.match(CONFIRMATION_MATCH);
    return match[0];
}

export const getIsConfirmedAccount = () => {
    return JSON.parse(sessionStorage.getItem(ACC_CONFIRMED));
};

export const setIsConfirmedAccount = value => {
    sessionStorage.setItem(ACC_CONFIRMED, JSON.stringify(value));
};

export const removeIsConfirmedAccount = () => {
    sessionStorage.removeItem(ACC_CONFIRMED);
}

export const ConfirmationPage = props => (
    <AuthenticationLayout {...props}>
        <Confirmation />
    </AuthenticationLayout>
);

export const Confirmation = props => {
    const translate = useTranslate();
    const classes = useStyles(props);
    const location = useLocation();
    const [confirm] = useConfirmation();

    useEffect(() => {
        if (location &&
            location.pathname &&
            location.pathname.includes('confirmation')
        ) {
            confirm(getConfirmationId(location));
        }
    }, []);


    return (
        <>
            <Typography
                variant='h4'
                color='textPrimary'
                className={classes.title}
            >
                {translate('resources.registrations.title')}
            </Typography>
            <SignUpForm {...props} />
        </>
    );
};

export const useConfirmation = () => {
    const [update, state] = useUpdate('registrations');
    const notify = useLayer7Notify();
    const history = useHistory();

    const confirm = (id) => {
        update(
            {
                payload: {
                    id,
                },
            },
            {
                action: CRUD_UPDATE,
                onSuccess: ({ data }) => {
                    if (data && data.status === 'APPROVAL') {
                        setIsConfirmedAccount(true);
                        history.push('/signup');
                    } else {
                        notify(
                            'resources.registrations.notifications.temporary_email_confirmation_error',
                            'error',
                        );
                    }
                },
                onFailure: error => {
                    if (error && error.message) {
                        notify(error.message, 'error');
                    } else {
                        notify(
                            'resources.registrations.notifications.general_exception',
                            'error',
                        );
                    }
                },
            },
        );
    };

    return [confirm, state];
};

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(6),
    },
    errorIcon: {
        '& ion-icon': {
            color: '#FFCB00',
            height: '35px',
            width: '35px',
        },
    },
    confirmation: {
        marginTop: '15px',
    },
}));
