import React from 'react';
import {
    createTheme,
    makeStyles,
    ThemeProvider,
    CssBaseline,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Notification } from 'react-admin';

import { nscorpTheme } from '../theme';
import { Footer, Header } from './';

export const UnAuthenticatedLayoutWithTheme = ({ children, showActions }) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={createTheme(nscorpTheme)}>
            <CssBaseline />
            <Container className={classes.root} maxWidth="xl">
                <Header showActions={showActions} />
                <main className={classes.main}>{children}</main>
                <Footer />
                <Notification />
            </Container>
        </ThemeProvider>
    );
};

const useStyles = makeStyles(
    theme => ({
        '@global': {
            '#root': {
                minHeight: '100vh',
            },
        },
        root: {
            display: 'flex',
            padding: '0 38px',
            flexDirection: 'column',
            minHeight: '100vh',
            background: nscorpTheme.palette.secondary.main,
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            margin: '0 -38px',
            background: nscorpTheme.palette.common.white,
        },
    }),
    {
        name: 'ApiFaqsUnAuthenticatedLayout',
    }
);
