import React from 'react';
import { ApiHubLayout } from 'layer7-apihub';
import get from 'lodash/get';

import { nscorpTheme } from '../theme';
import { FeedbackButton } from '../ui/feedback/Feedback';
import { qualtricsExistingUsersUrl } from '../constants';

export const Layout = props => {
    return (
        <>
            <FeedbackButton to={qualtricsExistingUsersUrl} />
            <ApiHubLayout {...props} theme={nscorpTheme} />
        </>
    );
};
export const guessApihubTenantName = (location = global.window.location) => {
    return location.host.split('.')[0];
};

export const guessApihubUrl = (location = global.window.location) => {
    return get(location, 'origin', '');
};
