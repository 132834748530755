import React from 'react';
import { useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DefaultDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

export const CustomDialog = ({ title, content, translatedContent, open, onClose }) => {
    const translate = useTranslate();

    return (
        <Dialog
            aria-labelledby="dialog_title"
            open={open}
            onClose={onClose}
            disableBackdropClick={true}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="dialog_title" onClose={onClose}>
                {translate(title)}
            </DialogTitle>
            <DialogContent dividers>
                {translatedContent || translate(content)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate(
                        'common.labels.close'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useDialogTitleStyles = makeStyles(
    theme => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
    {
        name: 'CustomDialogTitle',
    }
);

const DialogTitle = ({ children, onClose, ...rest }) => {
    const classes = useDialogTitleStyles(rest);
    const translate = useTranslate();

    return (
        <DefaultDialogTitle
            disableTypography
            className={classes.root}
            {...rest}
        >
            <Typography variant="h6">{children}</Typography>
            <IconButton
                aria-label={translate(
                    'common.labels.close'
                )}
                className={classes.closeButton}
                onClick={onClose}
            >
                <Close />
            </IconButton>
        </DefaultDialogTitle>
    );
};
