import React from 'react';
import {
    createTheme,
    makeStyles,
    ThemeProvider,
    CssBaseline,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Notification } from 'react-admin';

import { nscorpTheme } from '../../theme';
import { Footer } from '../Footer';
import { Header } from '../Header';

export const UnAuthenticatedLayout = ({ children, showActions }) => {
    const classes = useStyles();

    return (
        <Container className={classes.root} maxWidth="xl">
            <div item className={classes.heroBackdrop} />
            <Header showActions={showActions} />
            <main className={classes.main}>{children}</main>
            <Footer />
            <Notification />
        </Container>
    );
};

export const UnAuthenticatedLayoutWithTheme = props => {
    return (
        <ThemeProvider theme={createTheme(nscorpTheme)}>
            <CssBaseline />
            <UnAuthenticatedLayout {...props} />
        </ThemeProvider>
    );
};

const useStyles = makeStyles(
    theme => ({
        '@global': {
            '#root': {
                minHeight: '100vh',
            },
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            padding: '0 38px',
            position: 'relative',
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            margin: '0 138px',
            [theme.breakpoints.down('md')]: {
                margin: '0 24px',
            },
            zIndex: 2,
        },
        heroBackdrop: {
            background: 'rgba(0, 0, 0, 0.4)',
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            zIndex: 1,
        },
    }),
    {
        name: 'LandingPageUnAuthenticatedLayout',
    }
);
