import React, { useEffect, useState } from 'react';
import {
    PasswordInput,
    required,
    SimpleForm,
    TextInput,
    useLogin,
    useTranslate,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Link, Typography } from '@material-ui/core';
import get from 'lodash/get';

import { LoginToolbar } from '.';
import { getIsNewAccCreated, setIsNewAccCreated } from '../authProvider';
import { CustomDialog } from '../../common';

export const LoginForm = props => {
    const { toolbarprops, ...rest } = props;

    const login = useLogin();
    const classes = useStyles(rest);
    const translate = useTranslate();

    const [isLoading, setIsLoading] = useState(null);
    const [error, setError] = useState(null);
    const [authScheme] = useState(null);
    const isAccountCreated = getIsNewAccCreated() || false;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (isAccountCreated) {
                setOpen(true);
                setIsNewAccCreated(false);
            }
        }, 2000);
    }, [isAccountCreated]);

    const handleClose = () => {
        setOpen(false);
    };

    const submit = async ({ username, password }) => {
        setError(null);
        setIsLoading(true);

        const params = {
            scheme: 'credentials',
            provider: get(authScheme, 'uuid', null),
            username,
            password,
        };

        try {
            await login(params);
        } catch {
            setError('apihub.login.notifications.invalid_credentials');
        }

        setIsLoading(false);
    };

    return (
        <>
            <SimpleForm
                className={classes.form}
                save={submit}
                toolbar={
                    <LoginToolbar
                        loading={isLoading}
                        error={error}
                        {...toolbarprops}
                    />
                }
                {...props}
            >
                <TextInput
                    source="username"
                    type="text"
                    label="apihub.login.fields.username"
                    variant="outlined"
                    fullWidth
                    validate={required()}
                />
                <PasswordInput
                    source="password"
                    label="apihub.login.fields.password"
                    variant="outlined"
                    fullWidth
                    validate={required()}
                />
            </SimpleForm>

            <Typography variant="body1">
                <Link component={RouterLink} to="/reset-password">
                    {translate('apihub.login.actions.forgot_password')}
                </Link>
            </Typography>
            <Typography
                className={classes.noAccount}
                variant="body1"
                align="center"
            >
                {translate('resources.registrations.actions.no_account')}
            </Typography>
            <Typography variant="body1" align="center">
                <Link component={RouterLink} to="/signup">
                    {translate(
                        'resources.registrations.actions.create_account'
                    )}
                </Link>
            </Typography>

            <CustomDialog
                title={'common.labels.confirmation'}
                content={'resources.registrations.notifications.confirmation'}
                open={open}
                onClose={handleClose}
            />
        </>
    );
};

const useStyles = makeStyles(
    () => ({
        form: {
            '& >:first-child': {
                padding: 0,
            },
        },
        noAccount: {
            margin: '8px 0',
        },
    }),
    {
        name: 'Layer7LoginForm',
    }
);
