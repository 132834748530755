export const Logos = {
    white: 'Primary NS Logo_White.png',
    white_horizontal: 'Horizontal App Logo_White.png',
    black: 'Primary NS Logo_Black.png',
};

export const Routes = {
    default: '/apis',
    apis: '/apis',
    applications: '/applications',
    wiki: '/wiki',
};
