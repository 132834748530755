import { makeStyles } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Brightness1OutlinedIcon from '@material-ui/icons/Brightness1Outlined';
import Button from '@material-ui/core/Button';
import { useTranslate } from 'react-admin';

export const TermsAndConditions = props => {
    const translate = useTranslate();
    const classes = useStyles();

    const handleClickClose = () => {
        props.handleClose();
    };

    return (
        <Container className={classes.root} maxWidth="lg">
            <Typography
                variant="h2"
                align="left"
                className={classes.sectionTitle}
            >
                Norfolk Southern Terms & Conditions.
            </Typography>
            <Typography className={classes.sectionText}>
                1. GENERAL. Norfolk Southern Railway Company, including but not
                limited to its subsidiaries, affiliates and parent (collectively
                "NS"), make available the information contained in
                developer.nscorp.com and api.nscorp.com (collectively referred
                to as this “Site") subject to the terms and conditions set forth
                below (the “Terms”). Use of the Site is governed by these Terms
                and our Privacy Policy. Your use of this Site constitutes your
                acceptance of the Terms and the Privacy Policy without
                modification by you. Even though you may have arrived to the
                Site through a website or mobile application operated or
                controlled by a third party, you understand and agree that these
                Terms are entered into between you and NS. You further
                understand that NS may discontinue your use or access to this
                Site for any reason at any time.
            </Typography>
            <Typography className={classes.sectionText}>
                2. ELIGIBILITY. In order to use the Site, the following must be
                true: (i) you are age 18 or over; (ii) you have read and
                understood these Terms and the Privacy Policy and agree to be
                bound by the terms and conditions set forth herein and in the
                Privacy Policy.
            </Typography>
            <Typography className={classes.sectionText}>
                3. LIMITED LICENSE. This Site is solely intended for the
                convenience of the customers of NS. NS reserves the right to
                exclude from this Site any person at any time with or without
                cause. NS reserves the right at its sole discretion to terminate
                your access to this Site at any time with or without cause. Your
                access to this Site constitutes a revocable, nonexclusive
                limited license to access this Site under the Terms solely for
                the purposes of conducting business with NS or any its
                affiliates and subsidiaries. The documents and information
                received from this Site are solely for your own use and are not
                to be sold, released, licensed or distributed to third parties.
                You agree not to transmit to this Site any unlawful,
                pornographic, libelous, harassing or otherwise prohibited or
                objectionable material.
            </Typography>
            <Typography className={classes.sectionText}>
                4. UNAUTHORIZED USE. As a condition of your use of this Site,
                you agree to make use of the Site in a manner consistent with
                its intended and allowable purposes. You may not use the Site in
                any manner that could damage, disable, overburden or impair any
                NS server or network. You may not block or interfere with any
                other party’s allowable use of the Site. You may not attempt to
                gain unauthorized or premature access to the Site, to any
                information placed there, or to any NS server or network through
                the Site through overwhelming volume inquiry, hacking, password
                mining, or other means, including but not limited to automatic
                or manual mass attempts at access. You are authorized only to
                gain access to this Site, or to the information stored therein
                or reachable through, in the permitted manner at the permitted
                times. Unauthorized use of this Site shall subject you to
                liability to NS for any damage done to this Site, NS servers or
                networks of NS.
            </Typography>
            <Typography className={classes.sectionText}>
                5. ACCURACY OF INFORMATION. The information provided by this
                site is provided "AS IS". Your use of this Site is solely at
                your own risk. NS will endeavor to provide accurate and complete
                information; however, NS does not guarantee or represent that
                the information provided through this Site will be free from
                error, current or complete.
            </Typography>
            <Typography className={classes.sectionText}>
                6. MODIFICATIONS TO THE SITE. NS may at its discretion change or
                eliminate the features of this Site or cease operation of this
                Site without notice to you. You should check the Terms from time
                to time when you use the Site to determine if any changes have
                been made. You can determine when the Terms were last revised by
                referring to the “Last Modified” notation above. If you use the
                Site after the amended Terms have been posted, you will be
                deemed to have agreed to the amended Terms. If any of the
                provisions of these Terms are not acceptable to you, your sole
                and exclusive remedy is to discontinue your use of the Site.
            </Typography>
            <Typography className={classes.sectionText}>
                7. PASSWORD SECURITY. You agree to keep confidential your user
                identification and/or password. You agree to notify NS
                immediately in the event that your user identification and/or
                your password is compromised, lost or stolen.
            </Typography>
            <Typography className={classes.sectionText}>
                8. TRANSMISSION SECURITY. Transmission of information through
                the Internet is inherently not secure and might result in the
                loss, corruption or disclosure of the material. You accept all
                risks regarding the security and confidentiality of your
                transmissions to and from this Site.
            </Typography>
            <Typography className={classes.sectionText}>
                9. LINKS. NS may from time to time provide links from this Site
                to other sites as a convenience to you. Your use of such other
                Sites is solely at your own risk. NS is not responsible for the
                content of any other site and neither warrants the accuracy of
                nor endorses the content of such other Sites.
            </Typography>
            <Typography className={classes.sectionText}>
                10. CONFIDENTIALITY. You agree to take reasonable steps to
                maintain the confidentiality of the information that you receive
                from this Site and agree not to disclose the confidential
                information of NS, including but not limited to prices, services
                and conditions of service, to others except as permitted for the
                purposes allowed by this limited license.
            </Typography>
            <Typography className={classes.sectionText}>
                11. INVESTMENT DECISIONS AND FORWARD LOOKING INFORMATION. The
                information contained in this Site is not intended to qualify,
                amend, modify, supplement or in any other fashion change any
                information disclosed by NS under applicable federal and state
                corporate and securities laws.
            </Typography>
            <Typography className={classes.sectionText}>
                12. INTELLECTUAL PROPERTY. The information found on this Site
                will contain the trademarks, trade names, logos and service
                marks of NS and/or other companies and is protected by the
                copyright and trademark laws of the United States of America and
                of other countries with regard to such trademarks, trade names,
                logos and service marks. The use of these trademarks, trade
                names, logos and service marks on this Site does not imply a
                license to you of any nature to make use of them. Unauthorized
                use of these trademarks, trade names, logos and service marks is
                prohibited. Any rights not granted to you under this agreement
                are reserved to NS.
            </Typography>
            <Typography className={classes.sectionText}>
                13. DISCLAIMER OF WARRANTIES, LIMITATIONS OF LIABILITY. NS
                SPECIFICALLY DISCLAIMS ANY WARRANTIES, EXPRESS OR IMPLIED, WITH
                REGARD TO THIS SITE, ITS SOFTWARE AND THE INFORMATION PROVIDED
                THROUGH THIS SITE, INCLUDING BUT NOT LIMITED TO WARRANTIES OF
                MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NS DOES NOT
                WARRANT THAT THIS SITE WILL MEET YOUR REQUIREMENTS OR THAT THIS
                SITE WILL BE IN OPERATION AT ALL TIMES. NS DOES NOT WARRANT THAT
                THIS SITE WILL BE WITHOUT ERROR OR VIRUS FREE. NS DOES NOT
                ASSUME ANY RESPONSIBILITY WHATSOEVER ARISING OUT OF YOUR USE OF
                THIS SITE, REGARDLESS OF WHETHER NEGLIGENCE ON THE PART OF NS
                MAY HAVE CAUSED OR CONTRIBUTED TO ANY LOSS OR DAMAGE SUFFERED BY
                YOU AS A RESULT OF USING OR RELYING UPON THIS SITE. IN NO EVENT
                UNDER ANY THEORY OF LAW, WHETHER CONTRACT, TORT, WARRANTY OR
                OTHERWISE, SHALL NS HAVE ANY LIABILITY TO ANY PERSON FOR
                INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES OF ANY
                DESCRIPTION, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
                PROFITS, COST OF CAPITAL OR BUSINESS INTERRUPTION EXPENSES AS A
                RESULT OF YOUR USE OF THIS SITE. THE FOREGOING DISCLAIMER OF
                WARRANTIES AND LIMITATION OF LIABILITY SHALL EXTEND TO INCLUDE
                NOT ONLY NORFOLK SOUTHERN RAILWAY COMPANY BUT ALSO NORFOLK
                SOUTHERN CORPORATION, AS WELL AS ANY OTHER CORPORATE
                SUBSIDIARIES OR AFFILIATES OF NORFOLK SOUTHERN CORPORATION OR
                NORFOLK SOUTHERN RAILWAY COMPANY. THE FOREGOING DOES NOT AFFECT
                ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
                APPLICABLE LAW. SOME JURISDICTIONS DO NOT ALLOW CERTAIN
                LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS
                OF LIABILITY MAY NOT APPLY TO YOU. IF YOU ARE A CALIFORNIA
                RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE § 1542, WHICH SAYS: A
                GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
                DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE
                TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST
                HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.
                IF YOU ARE A RESIDENT OF A STATE WITH PROTECTIONS SIMILAR TO
                CALIFORNIA CIVIL CODE § 1542, YOU HEREBY WAIVE SUCH PROVISIONS
                OR PROTECTIONS.
            </Typography>
            <Typography className={classes.sectionText}>
                14. INDEMNIFICATION. You agree to indemnify, defend, and hold NS
                and any of its officers, directors, employees, licensors, and
                agents harmless from and against any and all third-party claims,
                demands, liabilities, costs, or expenses, including attorneys’
                fees and costs, arising from or related to: (i) any breach by
                you of these Terms or another agreement, (ii) your use of
                content or data available on the Site in an unauthorized manner,
                and/or (iii) a violation by you of any and all applicable laws,
                rules, or regulations.
            </Typography>
            <Typography className={classes.sectionText}>
                15. NO THIRD PARTY BENEFICIARIES. This agreement is not intended
                to inure to the benefit of any third-party.
            </Typography>
            <Typography className={classes.sectionText}>
                16. NO JOINT VENTURE. Your use of this Site and the information
                provided therefrom under this agreement does not constitute a
                joint venture, partnership or agency relationship between you
                and NS.
            </Typography>
            <Typography className={classes.sectionText}>
                17. ENCRYPTION. This Site uses encryption technology that might
                be controlled for export under the laws of the United States of
                America. You agree to comply with the requirements of such laws
                in the event that you fall within the controlled categories
                defined under these laws.
            </Typography>
            <Typography className={classes.sectionText}>
                18. GOVERNING LAW. This Site is controlled by NS from its
                offices in Atlanta, Georgia. By accessing this Site, you agree
                that all matters relating to your use of this Site shall be
                governed by the laws of the State of Georgia. You also agree to
                the exclusive personal jurisdiction and venue of the courts of
                the City of Atlanta, Georgia, with regard to such matters.
            </Typography>
            <Typography className={classes.sectionText}>
                19. ELECTRONIC COMMUNICATIONS. This Site permits you to elect to
                receive emails, text messages, and other forms of electronic
                communications (collectively, “Electronic Communications”) from
                NS. Should you so elect to receive Electronic Communications,
                you will receive confirmation of such election from NS. You will
                be asked to reply to that confirmation to affirm your consent to
                receive Electronic Communications from NS. The frequency with
                which you will receive Electronic Communications will vary by
                your and NS’ business needs. You may discontinue receiving any
                Electronic Communications from NS by following the instructions
                found on this Site or at the bottom of such Electronic
                Communications. Message and data rates may apply.
            </Typography>
            <Typography className={classes.sectionText}>
                20. ENTIRE AGREEMENT. This agreement, together with Attachment A
                hereto, contain the entire agreement between the parties with
                regard to the use by you of this Site. No transmission by you to
                NS can serve to modify or amend in any fashion any provision of
                this agreement. Should any part of this agreement be held by a
                court of component jurisdiction to be invalid or unenforceable,
                the remaining provisions shall remain in full force and effect.
                The waiver by NS of any provision of this agreement shall not
                constitute either an ongoing waiver of a requirement of this
                agreement or a waiver of any other breach of this agreement.
                Notwithstanding the foregoing, NS may at its discretion and
                without notice to you change the terms and conditions of this
                agreement. Any such change will be effective with regard to you
                for any access by you which occurs after the change in terms and
                conditions by NS is made to this Site.
            </Typography>
            <Typography className={classes.sectionText}>
                21. CONTACT INFORMATION. If you have any questions or concerns,
                please contact us at 1800-635-5768
            </Typography>
            <Typography
                variant="h2"
                align="left"
                className={classes.sectionTitle}
            >
                <br></br>
                Norfolk Southern Privacy Policy.
            </Typography>
            <Typography className={classes.subTitle}>
                IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT USE
                THE SERVICE.
            </Typography>
            <br></br>
            <Typography className={classes.subTitle}>
                1. The information that we collect and how we collect it
            </Typography>
            <Typography className={classes.sectionText}>
                We collect a variety of information from you through this
                (“Service”), including information that you provide and
                information that we passively collect. In this Privacy Policy we
                refer to all of this information, collectively, as the
                "Information."
            </Typography>
            <Typography className={classes.sectionText}>
                The categories of personal information that we collect are
                listed below. Not all categories will be collected about every
                customer:
            </Typography>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Personal identifiers, such as name and work address
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Device and online identifiers and related information, such as
                telephone number, mobile number and email address
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Internet, application, and network
            </span>
            <Typography className={classes.subTitle}>
                2. Information You Provide to Us
            </Typography>
            <Typography className={classes.sectionText}>
                When you create an account with us, supply information to us
                regarding your personal preferences, or interact with us in any
                other way, we may request and you provide Information about
                yourself – for instance, Information that identifies you or
                provides us a way to contact you. This may include your name,
                email address, and phone number (which is sometimes referred to
                as “Personal Information”).
            </Typography>
            <Typography className={classes.subTitle}>
                3. Information We Collect Automatically
            </Typography>
            <Typography className={classes.sectionText}>
                In addition to the Information you provide to us directly, we
                may automatically collect information about your use of the
                Service as follows:
            </Typography>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Web Log Data. When you use our websites (including this website
                and any other on which this Privacy Policy is posted), we may
                passively collect and analyze information such as your IP
                address, browser types, browser language, operating system, the
                state or country from which you accessed the Service, software
                and hardware attributes (including device IDs) referring and
                exit pages and URLs, platform type, files you download, domain
                names, landing pages, pages viewed and the order of those pages,
                the amount of time spent on particular pages or whether you have
                clicked content, the terms you use in searches on our Services,
                the date and time you used the Service and upload or post
                content, error logs, and other similar I We or third parties we
                work with may also associate a unique identifier or "UID" with
                your browser, such as through a "cookie." To learn more about
                cookies, see the below section titled "Cookies, Mobile Ad IDs
                and Other Technologies."
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Mobile Data Collection and SDKs: If you download our mobile
                application, we will collect, and may allow third parties to
                collect (including through mobile "SDKs") information about your
                mobile device such as device identifiers, your IP address,
                precise location information, and information connected to how
                your device interacts with our Service and those using our
                Service. Location information may be used to provide customized
                location-based services, personalized content and other
                information that may be of interest to you. An SDK is a bit of
                computer code that app developers can include in their apps to
                enable data to be collected, and related services to be
                implemented.
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Cookies and Other Technologies. We and our third-party service
                providers may use cookies, clear GIFs, pixel tags, beacons, and
                other technologies that help us better understand user behavior,
                personalize preferences, perform research and analytics, order
                history, and improve the products and services we provide. We or
                our third-party service providers also may use certain of these
                technologies in emails to help us track email response rates,
                identify when our emails are viewed, and track whether our
                emails are forwarded.
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Cookies and Pixels. Cookies are files that websites send to your
                computer or other Internet-connected device to uniquely identify
                your browser or to store information or settings on your device.
                We use them to uniquely recognize your browser when you visit
                our websites. Cookies also allow us and third parties to provide
                you with a better and more personalized experience and to make
                you aware of our products and promotions that may be of interest
                to you. Our Service may use HTTP cookies, HTML5 cookies and
                other types of local storage (such as browser-based or
                plugin-based local storage). Most web browsers automatically
                accept cookies, but your browser may allow you to modify your
                browser settings to decline certain cookies if you prefer. If
                you disable cookies, you may not be able to use all of the
                features and functionality of this Service. To control which Web
                servers collect Information by automated means, we may place
                tags on our Web pages called "Web beacons," which are small
                files that link Web pages to particular Web servers and their
                cookies. We also may send instructions to your device using
                JavaScript or other computer languages to gather the sorts of
                Information described above and other details your interactions
                with the Service.
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Web Analytics. We may use third-party Web analytics services on
                our Service, such as those of Google Analytics. These service
                providers use the technology described above to help us analyze
                how users use the Service. The Information collected by the
                technology (including your IP address) will be disclosed to or
                collected directly by these service providers, who use the
                information to evaluate your use of the Service.
            </span>
            <Typography className={classes.subTitle}>
                4. E-mails we send
            </Typography>
            <Typography className={classes.sectionText}>
                If you place a request to receive notifications from Norfolk
                Southern, we may use that e-mail address to confirm your
                request, confirm the creation of any account, or to notify you
                of important information or changes to your request and/or our
                policies and practices. If you do not wish to receive these
                e-mails, you may "opt-out" or "unsubscribe" from these e-mails
                by following the instructions contained in the e-mail.
            </Typography>
            <Typography className={classes.subTitle}>
                5. How we use your information
            </Typography>
            <Typography className={classes.sectionText}>
                We may use your Information and/or combine any or all elements
                of your Information for several purposes, including:
            </Typography>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                To fulfill your order or requests for services and provide
                customer service
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                To create and maintain your account
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                To conduct auditing and monitoring of transactions and
                engagement
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                To conduct marketing and personalization
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                To protect the security and integrity of our websites, mobile
                services and our business, and help prevent fraud
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                To update our operational and technical functionality
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                To conduct business analysis, such as analytics, projections,
                identifying areas for operational improvement
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                To conduct research and development
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                To fulfill legal or contractual obligations
            </span>
            <Typography className={classes.subTitle}>
                6. How we share the information
            </Typography>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                App Store Providers. We may provide your identity and mobile
                device identifier to third-party app store providers (for
                example, the Apple App Store) to allow you to download our
                mobile apps.
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Business Transfers. Information may be transferred or disclosed
                as part of any merger, acquisition, sale of some or all our
                assets or transition of service to another provider, including
                for due diligence regarding such a transaction. In the unlikely
                event of an insolvency, bankruptcy or receivership, Information
                also may be transferred as a business asset.
            </span>
            <span className={classes.sectionBulletPoint}>
                <Brightness1OutlinedIcon
                    className={classes.sectionBulletPointIcon}
                />
                Protection of Norfolk Southern and Others. We may access,
                monitor, use or disclose your Information or communications to
                do things like: (i) comply with the law or respond in good faith
                to requests from law enforcement or government entities, or
                other legal process; (ii) protect the rights or property of
                ourselves, our agents, our customers, and others, including to
                enforce our agreements, policies, and terms of use; (iii)
                investigate behavior that is potentially illegal or fraudulent,
                or is in violation of our posted Terms of Use, and (iv) respond
                to emergencies.
            </span>
            <Typography className={classes.subTitle}>
                7. Security of your personal information
            </Typography>
            <Typography className={classes.sectionText}>
                Norfolk Southern is committed to online data security, and for
                that reason we take technical and organizational measures to
                protect your data against accidental or unlawful destruction or
                accidental loss, alteration, unauthorized disclosure, or access.
                However, no method of transmission over the Internet or via
                mobile device, or method of electronic storage, is secure.
                Therefore, while we strive to protect your information, we
                cannot guarantee its absolute security.
            </Typography>
            <Typography className={classes.sectionText}>
                You are responsible for preventing unauthorized access to your
                account and Information through your computer or other devices.
                Do not share your password with anyone and do limit access to
                your computer or other devices by signing off after you have
                finished accessing your account.
            </Typography>
            <Typography className={classes.subTitle}>
                8. How to access or update your information
            </Typography>
            <Typography className={classes.sectionText}>
                If you would like to update or modify your Personal Information
                or disable your account to prevent future purchases through that
                account, you may do that through your online account settings.
            </Typography>
            <Typography className={classes.subTitle}>
                9. We may change this privacy policy
            </Typography>
            <Typography className={classes.sectionText}>
                Modifications or changes to the Privacy Policy will be reflected
                in the most current Privacy Policy, which is available through
                the Service. We reserve the right to modify this Privacy Policy
                at any time, so we encourage you to review it frequently. If we
                make a material change to this Privacy Policy, we will notify
                you as required by law. Your continued use of the Service
                following any changes signifies your acceptance of our Privacy
                Policy as modified.
            </Typography>
            <Typography className={classes.subTitle}>10. Questions?</Typography>
            <Typography className={classes.sectionText}>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at 1800-635-5768
            </Typography>
            <br></br>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    handleClickClose();
                }}
            >
                {translate('common.labels.close')}
            </Button>
        </Container>
    );
};

const useStyles = makeStyles(
    theme => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '20px',
            paddingBottom: '20px',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        sectionTitle: {
            color: '#034764',
            padding: '10px 15px 15px 15px',
        },
        subTitle: {
            width: '100%',
        },
        sectionText: {
            padding: '10px 15px 15px 15px',
        },
        sectionBulletPoint: {
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            padding: '0 0 5px 20px',
        },
        sectionBulletPointIcon: {
            fontSize: '1rem',
            margin: '5px 5px 0 0',
        },
        paper: {
            position: 'absolute',
            width: '80%',
            maxHeight: '90%',
            overflow: 'scroll',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #034764',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
    {
        name: 'LandingPageFooter',
    }
);
