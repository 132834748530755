import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslate } from 'react-admin';

import { BrandLogo } from '../ui';
import { Logos } from '../ui/constants';

export const Header = props => {
    const translate = useTranslate();
    const classes = useAppbarStyles(props);
    const { showActions, ...rest } = props;
    return (
        <AppBar
            component="header"
            className={classes.root}
            position="static"
            color="transparent"
            elevation={0}
            {...rest}
        >
            <Toolbar disableGutters className={classes.toolbar}>
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <BrandLogo
                        className={classes.logo}
                        img={Logos.white_horizontal}
                    />
                    {showActions ? (
                        <div>
                            <Button
                                color="secondary"
                                className={classes.button}
                                component={RouterLink}
                                to="/login"
                                endIcon={
                                    <AccountCircleIcon
                                        className={classes.loginIcon}
                                    />
                                }
                            >
                                {translate('common.labels.login')}
                            </Button>
                        </div>
                    ) : null}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

const useAppbarStyles = makeStyles(
    theme => ({
        root: {
            background: theme.palette.secondary.main,
            margin: '0px -38px',
            width: 'initial',
            zIndex: 2,
        },
        toolbar: {
            minHeight: 80,
            margin: '0 38px',
            [theme.breakpoints.down('md')]: {
                margin: '0 24px',
            },
        },
        title: {
            paddingTop: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: theme.palette.primary.contrastText,
            '& svg': {
                fill: theme.palette.secondary.main,
                marginRight: theme.spacing(1),
            },
            '& span': {
                maxWidth: 100,
                textTransform: 'uppercase',
                lineHeight: 0.9,
                fontWeight: 'bold',
            },
            flexGrow: 1,
        },
        loginIcon: {
            fontSize: '26px !important',
        },
        button: {
            fontSize: '16px',
            lineHeight: '17px',
            fontFamily: 'gt-pressura-bold, sans-serif',
            letterSpacing: '0.64px',
        },
        logo: {
            width: '303px',
            height: '34px',
        },
    }),
    {
        name: 'LandingPageHeader',
    }
);
