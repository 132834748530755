import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

export const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography className={classes.copyright}>
                Copyright © 2024 Norfolk Southern. All Rights Reserved
            </Typography>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    copyright: {
        fontSize: '10pt',
        fontStyle: 'italic',
    },
}));
