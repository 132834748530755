import isEmpty from 'lodash/isEmpty';
const faker = require('faker');

export const companiesDataProvider = context => {
    const basePath = '/api/v1/company';
    return {
        async getList({ filter = {} }) {
            const url = `${basePath}/${filter.q}`;

            if (
                isEmpty(filter) ||
                (filter.q && filter.q.length === 1) ||
                (filter.q && filter.q.length > 300)
            ) {
                return { data: [], total: 0 };
            }

            const json = await context
                .fetchJson(url, {
                    credentials: 'include',
                })
                .catch(e => {
                    return { data: [], total: 0 };
                });
            if (json?.json?.data?.length === 0) {
                return { data: [], total: 0 };
            }

            return {
                data:
                    json?.json?.data?.map(({ ...item }) => ({
                        ...item,
                        id: faker.random.uuid(),
                    })) || [],
                total: json?.json.total || 0,
            };
        },
    };
};
