import React, { useState, useCallback } from 'react';
import { Link, InputHelperText, useInput, useTranslate } from 'react-admin';
import {
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Checkbox,
    Typography, makeStyles,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

import { TermsAndConditions } from 'app/src/layout';

export const EulaTermsInput = ({ helperText, ...rest }) => {
    const {
        input: { onChange, type, value, ...inputProps },
        meta: { error, touched },
    } = useInput({
        ...rest,
    });

    const handleChange = useCallback(
        (_, _value) => {
            onChange(_value);
        },
        [onChange]
    );

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        onChange={handleChange}
                        {...inputProps}
                    />
                }
                label={<TermsLabel />}
                labelPlacement="end"
            />
            <FormHelperText error={!!error}>
                <InputHelperText
                    touched={touched}
                    error={error}
                    helperText={helperText}
                />
            </FormHelperText>
        </FormGroup>
    );
};

export const TermsLabel = props => {
    const classes = useStyles(props);
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useState();
    const [modalStyle] = React.useState(getModalStyle);

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const handleOpen = event => {
        event.preventDefault();
        setIsOpen(true);
    };

    const handleCloseTerms = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Typography variant="body1">
                {translate(
                    'resources.registrations.fields.terms_of_use_acknowledgement'
                )}
                <Link to="#" onClick={handleOpen}>
                    {translate(
                        'resources.registrations.fields.terms_of_use'
                    )}
                </Link>
            </Typography>
            <div>
                <Modal
                    open={isOpen}
                    onClose={handleCloseTerms}
                    aria-labelledby="terms-and-conditions-modal"
                >
                    <div style={modalStyle} className={classes.paper}>
                        <TermsAndConditions handleClose={handleCloseTerms} />
                    </div>
                </Modal>
            </div>
        </>
    );
};

const useStyles = makeStyles(
    theme => ({
        paper: {
            position: 'absolute',
            width: 800,
            height: 700,
            overflow: 'scroll',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
    {
        name: 'EulaTermsInput',
    }
);
