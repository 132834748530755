import { makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import Typography from '@material-ui/core/Typography';

import { TermsAndConditions } from './TermsAndConditions';
import OverlayFooter from '../ui/images/OverlayFooter.png';
import OverlayMobileFooter from '../ui/images/OverlayMobileFooter.png';

export const Footer = ({ withLogo = true, ...rest }) => {
    const classes = useStyles(rest);
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    return (
        <footer className={classes.root}>
            <div>
                <Typography
                    id="contact-us"
                    variant="h3"
                    align="left"
                    className={classes.sectionTitleLight}
                >
                    Contact us
                </Typography>
                <Typography className={classes.sectionText}>
                    <ion-icon name="call-outline"></ion-icon>
                    800-635-5768
                </Typography>
                <Typography className={classes.sectionText}>
                    <ion-icon name="mail-outline"></ion-icon>
                    cshelpdesk@nscorp.com
                </Typography>
            </div>
            <div className={classes.terms}>
                <Link className={classes.link} variant="body1" underline="none">
                    <span onClick={handleOpen}>Terms and Conditions</span>
                </Link>
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="terms-and-conditions-modal"
                    >
                        <div style={modalStyle} className={classes.paper}>
                            <TermsAndConditions handleClose={handleClose} />
                        </div>
                    </Modal>
                </div>
            </div>
        </footer>
    );
};

const useStyles = makeStyles(
    theme => ({
        root: {
            zIndex: 2,
            backgroundImage: `url('${OverlayFooter}')`,
            backgroundSize: 'cover',
            margin: '0 -38px',
            paddingLeft: '176px',
            paddingRight: '176px',
            [theme.breakpoints.down('md')]: {
                backgroundImage: `url('${OverlayMobileFooter}')`,
                margin: '0px -38px',
                paddingLeft: '24px',
                paddingRight: '24px',
            },
        },
        logo: {
            fill: theme.palette.common.white,
            marginRight: theme.spacing(1),
        },
        link: {
            fontFamily: 'gt-pressura-regular, sans-serif',
            cursor: 'pointer',
            color: theme.palette.common.white,
            '& + &': {
                marginLeft: theme.spacing(4),
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '14px',
                lineHeight: '17px',
                letterSpacing: '0.07px',
            },
        },
        paper: {
            position: 'absolute',
            width: 800,
            height: 700,
            overflow: 'scroll',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        sectionTitleLight: {
            color: '#22bcb5',
            marginTop: '74px',
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
                marginTop: '30px',
            },
        },
        sectionText: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
            color: '#E7E7E2',
            '& ion-icon': {
                margin: '0 20px 0 5px',
                color: '#22bcb5',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '14px',
                lineHeight: '17px',
                letterSpacing: '0.07px',
            },
        },
        terms: {
            display: 'flex',
            paddingTop: '60px',
            paddingBottom: '27px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('md')]: {
                margingTop: '56px',
                paddingBottom: '21px',
            },
        },
    }),
    {
        name: 'LandingPageFooter',
    }
);
