import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Portal from '@material-ui/core/Portal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useAuthState } from 'ra-core';
import { Redirect } from 'react-router';

import { UnAuthenticatedLayoutWithTheme } from '../UnAuthenticatedLayout';
import { Routes } from '../../ui/constants';

export const ApiFaqs = () => {
    const classes = useStyles();
    const { loading, authenticated } = useAuthState();
    const [show, setShow] = React.useState({});
    const container = React.useRef(null);

    if (loading) {
        return null;
    }

    if (authenticated) {
        return <Redirect to={Routes.default} />;
    }

    const handleClick = field => {
        setShow({ ...show, [field]: !show[field] });
    };

    return (
        <UnAuthenticatedLayoutWithTheme showActions>
            <Container className={classes.root} maxWidth="lg">
                <Typography
                    variant="h2"
                    align="center"
                    className={classes.sectionTitle}
                >
                    Technical FAQs
                </Typography>
                <List
                    className={classes.faqList}
                    component="nav"
                    aria-label="Technical FAQs"
                >
                    <ListItem
                        button
                        className={`${classes.listItem}`}
                        onClick={() => handleClick('whatApiKey')}
                    >
                        {show['whatApiKey'] === true ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                        What is an API Key?
                    </ListItem>
                    {show['whatApiKey'] === true ? (
                        <Portal
                            container={container.current}
                            disablePortal={true}
                        >
                            <ul>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        API Keys are credentials associated with
                                        the client application which is needed
                                        to make calls to APIs that the
                                        application is subscribed to.
                                    </Typography>
                                </li>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        Customers that want to integrate must
                                        register for a - new NSARP (Norfolk
                                        Southern API Resource Platform) account
                                        through which an API Key is provided for
                                        your company to use in your software to
                                        identify your systems to Norfolk
                                        Southern.
                                    </Typography>
                                </li>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        The API Key is required to authorize
                                        your request’s access to data that
                                        Norfolk Southern provides through the
                                        APIs. It is important to keep the API
                                        Key secure.
                                    </Typography>
                                </li>
                            </ul>
                        </Portal>
                    ) : null}
                    <ListItem
                        button
                        className={`${classes.listItem}`}
                        onClick={() => handleClick('apiKey')}
                    >
                        {show['apiKey'] === true ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                        How Do I Get an API Key?
                    </ListItem>
                    {show['apiKey'] === true ? (
                        <Portal
                            container={container.current}
                            disablePortal={true}
                        >
                            <ul>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        You can request an API Key on Norfolk
                                        Southern API Resource Platform. Once the
                                        account has been authorized by our
                                        onboarding team, you will receive an API
                                        Key.
                                    </Typography>
                                </li>
                            </ul>
                        </Portal>
                    ) : null}
                    <ListItem
                        button
                        className={classes.listItem}
                        onClick={() => handleClick('whatApiSpecs')}
                    >
                        {show['whatApiSpecs'] === true ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                        What are your API Service Specifications
                    </ListItem>
                    {show['whatApiSpecs'] === true ? (
                        <Portal
                            container={container.current}
                            disablePortal={true}
                        >
                            <ul>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        API service specification is available
                                        in the API portal based on each API
                                        type. The specification Will have
                                        request and response field details and
                                        sample data.
                                    </Typography>
                                </li>
                            </ul>
                        </Portal>
                    ) : null}
                    <ListItem
                        button
                        className={classes.listItem}
                        onClick={() => handleClick('whatFormats')}
                    >
                        {show['whatFormats'] === true ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                        What Format are the Requests and Responses in?
                    </ListItem>
                    {show['whatFormats'] === true ? (
                        <Portal
                            container={container.current}
                            disablePortal={true}
                        >
                            <ul>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        Currently the request and response are
                                        JSON format.
                                    </Typography>
                                </li>
                            </ul>
                        </Portal>
                    ) : null}
                    <ListItem
                        button
                        className={`${classes.listItem}`}
                        onClick={() => handleClick('howOAuth')}
                    >
                        {show['howOAuth'] === true ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                        How do I use OAuth?
                    </ListItem>
                    {show['howOAuth'] === true ? (
                        <Portal
                            container={container.current}
                            disablePortal={true}
                        >
                            <ul>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        Getting started link (
                                        <a
                                            href="https://oauth.net/getting-started/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://oauth.net/getting-started/
                                        </a>
                                        ).
                                    </Typography>
                                </li>
                            </ul>
                        </Portal>
                    ) : null}
                    <ListItem
                        button
                        className={`${classes.listItem}`}
                        onClick={() => handleClick('keyExpire')}
                    >
                        {show['keyExpire'] === true ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                        Will my API Key ever expire?
                    </ListItem>
                    {show['keyExpire'] === true ? (
                        <Portal
                            container={container.current}
                            disablePortal={true}
                        >
                            <ul>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        No. Our API keys do not expire. They are
                                        De-activated when the customer or the
                                        user is not active.
                                    </Typography>
                                </li>
                            </ul>
                        </Portal>
                    ) : null}
                    <ListItem
                        button
                        className={`${classes.listItem}`}
                        onClick={() => handleClick('keyAccess')}
                    >
                        {show['keyAccess'] === true ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                        Can I use my API Key to access all NS APIs?
                    </ListItem>
                    {show['keyAccess'] === true ? (
                        <Portal
                            container={container.current}
                            disablePortal={true}
                        >
                            <ul>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        An API Key is specific to a customer for
                                        a specific API for security reasons.
                                    </Typography>
                                </li>
                            </ul>
                        </Portal>
                    ) : null}
                    <ListItem
                        button
                        className={`${classes.listItem}`}
                        onClick={() => handleClick('help')}
                    >
                        {show['help'] === true ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                        Who do I call for Help?
                    </ListItem>
                    {show['help'] === true ? (
                        <Portal
                            container={container.current}
                            disablePortal={true}
                        >
                            <ul>
                                <li className={classes.listItemDescription}>
                                    <Typography display="inline">
                                        Our dedicated&nbsp;
                                    </Typography>
                                    <a href="/#contact-us">
                                        Customer Service department
                                    </a>
                                    <Typography display="inline">
                                        &nbsp;is available for questions related
                                        to the APIs.
                                    </Typography>
                                </li>
                            </ul>
                        </Portal>
                    ) : null}
                    <ListItem
                        button
                        className={`${classes.listItem}`}
                        onClick={() => handleClick('examples')}
                    >
                        {show['examples'] === true ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                        Any examples of how to use the APIs?
                    </ListItem>
                    {show['examples'] === true ? (
                        <Portal
                            container={container.current}
                            disablePortal={true}
                        >
                            <ul>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        You can use our Equipment Status API to
                                        request shipment data for your
                                        equipment.
                                    </Typography>
                                </li>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        You can use Trip Plan API to request
                                        trip plan data.
                                    </Typography>
                                </li>
                                <li className={classes.listItemDescription}>
                                    <Typography>
                                        You can use our Gate Receipts APIs to
                                        request Gate receipt data for Intermodal
                                        facilities.
                                    </Typography>
                                </li>
                            </ul>
                        </Portal>
                    ) : null}
                </List>
            </Container>
        </UnAuthenticatedLayoutWithTheme>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: '120px',
    },
    faqList: {
        listStyle: 'none',
        paddingInlineStart: '25px',
        '& ul': {
            listStyleType: 'circle',
            paddingInlineStart: '55px',
        },
    },
    listItem: {
        display: 'flex',
        cursor: 'pointer',
        fontSize: '21px',
        color: '#034764',
        margin: '4px 0',
        '&::marker': {
            background: '#00253B 0% 0% no-repeat padding-box',
            border: '1px solid #00253B',
            fontSize: '20px',
        },
    },
    listItemDescription: {
        margin: '7px 0',
        '&::marker': {
            border: '1px solid #00253B',
            fontSize: '20px',
        },
    },
    sectionTitle: {
        color: '#027B75',
        marginTop: '60px',
        marginBottom: '23px',
    },
    subtitle: {
        color: '#034764',
    },
}));
