import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconApi = props => {
    return (
        <SvgIcon viewBox="0 0 16 11.761" fontSize="small" {...props}>
            <path
                d="M8.6,41.134a1.31,1.31,0,0,0,1.353,1.28H23.247a1.31,1.31,0,0,0,1.353-1.28V33.6H8.6Zm6.609-3.675h3.9a.366.366,0,1,1,0,.731h-3.9a.366.366,0,1,1,0-.731Zm-4.657-1.719a.341.341,0,0,1,0-.512.388.388,0,0,1,.541,0l2.473,2.341a.341.341,0,0,1,0,.512L11.093,40.42a.388.388,0,0,1-.541,0,.341.341,0,0,1,0-.512l2.184-2.085Z"
                transform="translate(-8.6 -30.652)"
            />
            <path
                d="M23.247,18.2H9.953A1.369,1.369,0,0,0,8.6,19.6v.881h16V19.6A1.4,1.4,0,0,0,23.247,18.2ZM9.779,20.043a.681.681,0,1,1,.657-.681A.664.664,0,0,1,9.779,20.043Zm1.99,0a.681.681,0,1,1,.657-.681A.664.664,0,0,1,11.769,20.043Zm2.01,0a.681.681,0,1,1,.657-.681A.664.664,0,0,1,13.779,20.043Z"
                transform="translate(-8.6 -18.2)"
            />
        </SvgIcon>
    );
};
