import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
    email,
    required,
    SimpleForm,
    TextInput,
    useTranslate,
} from 'react-admin';
import { makeStyles, Link, Typography } from '@material-ui/core';

import { SignUpToolbar } from './SignUpToolbar';
import { useSignup } from './SignUp';
import { setIsNewAccCreated } from '../authProvider';
import { EulaTermsInput } from './EulaTermsInput';
import { CompanyLookupInput } from '../../companies';
import { CustomDialog } from '../../common';

export const SignUpForm = props => {
    const classes = useStyles(props);
    const { toolbarProps } = props;
    const [signup] = useSignup();
    const translate = useTranslate();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [scacs, setScacs] = React.useState('');
    const [customerType, setCustomerType] = React.useState('');
    const [primarySix, setPrimarySix] = React.useState('');
    const [railCustomerNumber, setRailCustomerNumber] = React.useState('');
    const [classCode, setClassCode] = React.useState('');
    const [customError, setCustomError] = React.useState('');

    const onSubmit = form => {
        signup({
            ...form,
            Scac: scacs && scacs[0],
            CustomerType: customerType,
            Eid: primarySix,
            CustomerNumber: railCustomerNumber,
            CustomerLocation: classCode
        }, {
            onSuccess: () => {
                setIsNewAccCreated(true);
                history.push('/login');
            },
            onFailure: error => {
                if (error && error.message) {
                    setCustomError(error.message);
                }

                setOpen(true);
            }
        });
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <SimpleForm
                className={classes.form}
                save={onSubmit}
                toolbar={<SignUpToolbar {...toolbarProps} />}
            >
                <TextInput
                    source="FirstName"
                    type="text"
                    label="resources.registrations.fields.firstName"
                    variant="outlined"
                    fullWidth
                    validate={ValidateFirstName}
                />
                <TextInput
                    source="LastName"
                    type="text"
                    label="resources.registrations.fields.lastName"
                    variant="outlined"
                    fullWidth
                    validate={ValidateLastName}
                />
                <TextInput
                    source="Email"
                    type="email"
                    label="resources.registrations.fields.email"
                    variant="outlined"
                    fullWidth
                    validate={ValidateEmail}
                />
                <CompanyLookupInput
                    scacs={setScacs}
                    customerType={setCustomerType}
                    primarySix={setPrimarySix}
                    railCustomerNumber={setRailCustomerNumber}
                    classCode={setClassCode}

                />
                <EulaTermsInput
                    source="Terms"
                    type="checkbox"
                    validate={ValidateTerms()}
                />
            </SimpleForm>
            <Typography className={classes.existingLogin} variant="body1" align="center">
                {translate('resources.registrations.actions.already_exists')}
            </Typography>
            <Typography variant="body1" align="center">
                <Link component={RouterLink} to="/login">
                    {translate('resources.registrations.actions.login')}
                </Link>
            </Typography>
            <CustomDialog
                title={'common.labels.error'}
                translatedContent={customError}
                content={'resources.registrations.notifications.general_exception'}
                open={open}
                onClose={handleClose}
            />
        </>
    );
};

const ValidateTerms = () => value =>
    !value
        ? 'resources.registrations.fields.terms_of_use_validation'
        : undefined;
const ValidateEmail = [required(), email()];
const ValidateFirstName = [required(), nameValidation('FirstName')];
const ValidateLastName = [required(), nameValidation('LastName')];

export function nameValidation(
    field,
    message = 'resources.registrations.notifications.' + field
) {
    const reg = '^[a-zA-Z\\s\']*$';
    const regex = new RegExp(reg);

    return (value) => {
        return regex.test(value) ? undefined : message;
    };
}

const useStyles = makeStyles(
    theme => ({
        form: {
            '& >:first-child': {
                padding: 0,
            },
        },
        title: {
            fontSize: theme.typography.fontSize * 2,
            marginBottom: theme.spacing(6),
            color: theme.palette.getContrastText(
                theme.palette.background.default
            ),
        },
        root: {
            margin: '-15px 0 20px 0',
            width: '50%',
        },
        thumb: {
            height: 38,
            width: '100%',
            backgroundColor: theme.palette.grey[500],
            color: theme.palette.grey[800],
            borderRadius: 2,
            marginTop: -2,
            marginLeft: 0,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
            },
            '&$disabled': {
                height: 38,
                width: '100%',
                backgroundColor: theme.palette.success.dark,
                color: theme.palette.common.white,
                borderRadius: 2,
                marginTop: -2,
                marginLeft: 0,
            },
        },
        rail: {
            height: 34,
            borderRadius: 2,
            width: '200%',
        },
        track: {
            height: 34,
            borderRadius: 2,
        },
        disabled: {},
        existingLogin: {
          marginBottom: '8px',
        },
    }),
    {
        name: 'Layer7SignUpForm',
    }
);
