import React from 'react';

import { IconApps } from '../ui';
import { CompanyList } from './CompanyList';

export const companies = {
    icon: IconApps,
    list: CompanyList
};

export * from './CompanyLookupInput';
export * from './CompanyList';
