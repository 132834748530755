import { useMemo } from 'react';
import { theme as defaultTheme, useBranding } from 'layer7-apihub';
import merge from 'lodash/merge';
import createTheme from '@material-ui/core/styles/createTheme';
import createPalette from '@material-ui/core/styles/createPalette';
import { guessApihubTenantName, guessApihubUrl } from '../layout';

export const CHANGE_THEME = 'CHANGE_THEME';

export const themeReducer = (previousState = 'light', { type, payload }) => {
    if (type === CHANGE_THEME) {
        return payload;
    }
    return previousState;
};

export const changeTheme = newTheme => ({
    type: CHANGE_THEME,
    payload: newTheme,
});

const nscorpPalette = createPalette({
    primary: {
        main: '#22bcb5',
        color: '#40403c',
    },
    secondary: {
        main: '#00253b',
        contrastText: '#fff',
    },
    error: {
        main: '#ef4123',
        contrastText: '#fff',
    },
    warning: {
        main: '#ffcb00',
        contrastText: '#ffcb00',
    },
    text: {
        primary: '#40403C',
        secondary: '#fff',
    },
    background: {
        default: '#fafafa',
    },
    type: 'light',
});

export const nscorpTheme = createTheme(
    merge({}, defaultTheme, {
        palette: nscorpPalette,
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    body: {
                        fontFamily: 'adelle-sans-regular, sans-serif',
                    },
                },
            },
            MuiTypography: {
                root: {
                    color: '#292625',
                },
                h1: {
                    fontFamily: 'gt-pressura-bold, sans-serif',
                    fontSize: '64px',
                    lineHeight: '71px',
                    letterSpacing: '0.64px',
                },
                h2: {
                    fontFamily: 'gt-pressura-bold, sans-serif',
                    fontSize: '40px',
                    lineHeight: '44px',
                    letterSpacing: '0.4px',
                },
                h3: {
                    fontFamily: 'gt-pressura-bold, sans-serif',
                    fontSize: '30px',
                    lineHeight: '33px',
                    letterSpacing: '0.3px',
                },
                h4: {
                    fontFamily: 'adelle-sans-semibold, sans-serif',
                    fontSize: '28px',
                    lineHeight: '35px',
                    letterSpacing: '0.28px',
                },
                h5: {
                    fontFamily: 'adelle-sans-semibold, sans-serif',
                    fontSize: '20px',
                    lineHeight: '25px',
                    letterSpacing: '0.02px',
                },
                h6: {
                    fontFamily: 'gt-pressura-regular, sans-serif',
                    fontSize: '20px',
                    lineHeight: '25px',
                    letterSpacing: '0.02px',
                },
                body1: {
                    fontFamily: 'adelle-sans-regular',
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '0.08px',
                },
                body2: {
                    fontFamily: 'adelle-sans-regular',
                    fontSize: '14px',
                    lineHeight: '17px',
                    letterSpacing: '0.07px',
                },
                button: {
                    fontFamily: 'gt-pressura-bold, sans-serif',
                    fontSize: '16px',
                    lineHeight: '17px',
                    letterSpacing: '0.64px',
                },
                caption: {
                    fontFamily: 'adelle-sans-regular',
                    fontSize: '12px',
                    letterSpacing: '0',
                    lineHeight: '19px',
                },
                subtitle1: {
                    fontFamily: 'adelle-sans-regular',
                    fontSize: '17px',
                    lineHeight: '21px',
                    letterSpacing: '0',
                    color: '#292625',
                },
                subtitle2: {
                    fontFamily: 'adelle-sans-regular',
                    fontSize: '15px',
                    lineHeight: '18px',
                    letterSpacing: '0',
                    color: '#292625',
                },
                overline: {
                    fontFamily: 'adelle-sans-regular',
                },
                srOnly: {
                    fontFamily: 'adelle-sans-regular',
                },
            },
            MuiAppBar: {
                root: {
                    fontFamily: 'adelle-sans-regular',
                },
            },
            MuiCheckbox: {
                root: {
                    color: '#00253B',
                },
            },
            MuiButton: {
                root: {
                    fontFamily: 'gt-pressura-bold, sans-serif',
                    fontSize: '14px',
                    lineHeight: '1.25',
                    letterSpacing: '1.26px',
                },
                containedPrimary: {
                    color: nscorpPalette.primary.contrastText,
                    backgroundColor: nscorpPalette.primary.main,
                    padding: '10px 16px',
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: '#027B75',
                    },
                    '&:disabled': {
                        border: '2px solid #787878',
                        borderRadius: '6px',
                        background: '#d2d2cd',
                        color: '#40403C80',
                    },
                },
                containedSizeSmall: {
                    padding: '8px 16px',
                    fontSize: '14px',
                    '& MuiButtonLabel': {
                        padding: '0',
                    },
                },
                containedSizeLarge: {
                    padding: '11px 22px',
                    fontSize: '14px',
                },
                containedSecondary: {
                    color: nscorpPalette.secondary.contrastText,
                    backgroundColor: nscorpPalette.secondary.main,
                    padding: '10px 16px',
                    '&:disabled': {
                        border: '2px solid #787878',
                        borderRadius: '6px',
                        background: '#d2d2cd',
                        color: '#40403C80',
                    },
                },
                outlinedPrimary: {
                    padding: '9px 16px',
                    color: nscorpPalette.secondary.contrastText,
                    borderColor: nscorpPalette.secondary.contrastText,
                    backgroundColor: nscorpPalette.common.white,
                    '&:hover': {
                        backgroundColor: nscorpPalette.secondary.contrastText,
                        borderColor: nscorpPalette.secondary.contrastText,
                        color: nscorpPalette.common.white,
                    },
                    '&:disabled': {
                        border: '2px solid #787878',
                        borderRadius: '6px',
                        background: '#d2d2cd',
                        color: '#40403C80',
                    },
                },
                outlinedSecondary: {
                    color: nscorpPalette.primary.main,
                    borderColor: nscorpPalette.primary.main,
                    padding: '9px 16px',
                    '&:hover': {
                        color: nscorpPalette.common.white,
                        backgroundColor: '#027B75',
                        borderColor: '#027B75',
                    },
                    '&:disabled': {
                        border: '2px solid #787878',
                        borderRadius: '6px',
                        background: '#d2d2cd',
                        color: '#40403C80',
                    },
                },
                textPrimary: {
                    color: nscorpPalette.primary.main,
                },
                textSecondary: {
                    color: nscorpPalette.common.white,
                },
            },
            MuiInputBase: {
                root: {
                    fontFamily: 'adelle-sans-regular, sans-serif',
                },
            },
            MuiInputLabel: {
                root: {
                    fontFamily: 'adelle-sans-regular, sans-serif',
                },
            },
            MuiMenuItem: {
                root: {
                    fontFamily: 'adelle-sans-regular, sans-serif',
                },
            },
            MuiCardActions: {
                root: {
                    alignContent: 'center',
                    height: '100%',
                    padding: '16px',
                },
            },
            MuiFormLabel: {
                root: {
                    color: nscorpPalette.text.primary,
                },
            },
            MuiFormHelperText: {
                root: {
                    color: nscorpPalette.common.black,
                },
            },
            MuiTableCell: {
                root: {
                    fontFamily: 'adelle-sans-regular, sans-serif',
                },
            },
            Layer7ApplicationOverviewField: {
                overviewScrollFadeColor: {
                    background: `linear-gradient(to bottom, rgba(66, 66, 66, 0) 0%, rgba(66, 66, 66, 1) 100%)`,
                },
            },
        },
    })
);

export const useTheme = () => {
    const {
        APIHUB_URL,
        USE_BRANDING_THEME,
        TENANT_NAME,
        ORIGIN_HUB_NAME,
    } = global.APIHUB_CONFIG;

    const TENANT = TENANT_NAME || guessApihubTenantName();
    const URL = APIHUB_URL || guessApihubUrl();
    const API_URL_WITH_TENANT = `${URL}/api/${TENANT}`;

    const { logo, brandingTheme } = useBranding(
        API_URL_WITH_TENANT,
        ORIGIN_HUB_NAME
    );

    const theme = useMemo(() => {
        if (USE_BRANDING_THEME) {
            return brandingTheme;
        }
        return nscorpTheme;
    }, [USE_BRANDING_THEME, brandingTheme]);

    return { theme, logo };
};
