import React from 'react';

export const BrandLogo = props => {
    let path = props.img || 'Primary NS Logo_White.png';
    return (
        <img
            className={props.className}
            width="153px"
            height="40px"
            src={path}
            alt={'Logo'}
        />
    );
};
